<template>
   <div  class="inside-bg">
    <div class="container" style="padding-top:100px">
      <h1>Training Institutes</h1>
    </div>
    <div class="container">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Training institutes</li>
        </ol>
      </nav>
      <div class="row responses-filter-row">
          <div class="col-lg-3 mb-lg-3">
              <v-autocomplete class="custom-autocomplete" v-model="filter.course_id" multiple @change="getInstitutes"  :items="courses" item-value="value" item-text="text"  small-chips outlined deletable-chips><template #label>Course</template></v-autocomplete>
          </div>
          <div class="col-lg-3 mb-lg-3">
              <v-autocomplete class="resp-filter"  v-model="filter.city_id" multiple @change="getInstitutes" :items="locations" item-value="value" item-text="text"  small-chips outlined deletable-chips><template #label>Location</template></v-autocomplete>
          </div>
    </div>
      <div class="" id="training-institutes" v-if="institutes.length !== 0 ">
        <div v-for="institute in institutes" :key="institute.institute_id" class="card mb-2" @click="showInstiDetails(institute)">
          <div class="card-body">
            <div>{{institute.institute_name}}</div>
            <div><small><v-icon class="mdi" size="14px" color="#6c757d">{{$icons.mapMarker}}</v-icon>{{institute.city_name}}</small></div>
            <div v-if="institute.courses.length !== 0"><small><v-icon size="14px" color="#6c757d" class="mdi">{{ $icons.school }}</v-icon> {{ getInstiCourses(institute.courses)}}</small></div>
          </div>
        </div>
      </div>
    </div>
   </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
    name:'TrainingInstitutes',
    data: function(){
      return{
        institutes: [],
        action: new Actions(),
        filter: {
          course_id: null,
          city_id:null
        },
        courses:[],
        locations: []
      }
    },
    beforeMount(){
      this.getStates();
      this.getInstitutes();
      this.getInstituteCourses();
    },
    methods: {
      getInstitutes: function(){
        this.action.getRegisteredInstitutes(this.filter).then(res => {
          if(res.status){
            this.institutes = res.institutes;
          }
        }).catch(err =>{
          console.log(err);
        })
      },
      getInstituteCourses: function(){
        this.action.getRegisteredCourses().then(res => {
          if(res.status){
            res.courses.forEach(c => {
              this.courses.push({
                text: c.course_name,
                value: c.cid
              })
            })
          }
        }).catch(err =>{
          console.log(err);
        })
      },
      getInstiCourses: function(courses){
        return courses.map(course => course.course_name).join(', ');
      },
      showInstiDetails: function(institute){
      let param = institute.institute_name.replace(/[^A-Z0-9]+/ig, "-");
      if(param.length > 80){
        param = param.substring(0, 80);
      }
       param = param+'-'+institute.institute_id;
       window.open(`/training-institutes/${param}`,'_blank');
      },
      getStates: async function () {
          await this.action.getStates().then(async (data) => {
          this.locations.push({header: 'Top Metropolitan Cities'})
          await data.states.forEach(async (element) => {
              await element.cities.forEach(city => {
                  if(city.top_city == 1){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
                  }
              });
              this.locations.push({divider:true})
              this.locations.push({header: element.state_name});
              element.cities.forEach(city => {
                  if(city.top_city == 0){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:element.state_name})
                  }
              });
          });
      });
  },
    }
}
</script>
<style scoped>
  v-text-field__details{display: none !important;}
  .v-input__control fieldset {
    background-color: #fff !important; /* Your desired background color */
  }
  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }
#training-institutes .card {
  cursor: pointer;
 }
</style>